import React from 'react'
import Layout from '../../components/Layout/Layout'
import CareerImg from '../../assets/images/careers-img.webp'
import './style.scss'

function Career() {
  return (
    <Layout>
      <div className="career">
        <div>
          <img src={CareerImg} alt="" />
        </div>
        <div className="career-details">
          <header>
            <h1>Careers at Ayala Land Premier</h1>
            <h2>Grow with Us</h2>
            <p>
              Explore our career opportunities and become a part of a dynamic team committed to excellence and innovation. Join us on our
              journey to redefine luxury living.
            </p>
          </header>
          <a href="https://app.smartsheet.com/b/form/90adbee6f67c4bb9819473e28ab70e00">Corporate Opportunities</a>
          <a href="https://app.smartsheet.com/b/form/f978b7e96f754fbea6e4e0ddd4728012">Sales Career Opportunities</a>
        </div>
      </div>
    </Layout>
  )
}

export default Career
