import React, { ReactElement, useState, useEffect, useCallback } from 'react'
import loadable from '@loadable/component'

const Header = loadable(() => import('../Header/Header'))
const Footer = loadable(() => import('../Footer/Footer'))
const DisplayModal = loadable(() => import('./component/DisplayModal'))

import './Layout.scss'
import './Animations.scss'

const Layout = ({ children, props }: any): ReactElement => {
  const [showModal, toggleModal] = useState<boolean>(true)

  const [webConsent, setWebConsent] = useState<boolean>()
  const [consent, setConsent] = useState<any>()
  const [privacyPolicy, togglePrivacyPolicy] = useState<boolean>(false)

  useEffect(() => {
    let expire = getExpiry()
    if (new Date().getTime() > parseInt(expire ? expire : '0')) {
      localStorage.clear()
      setWebConsent(false)
      toggleModal(true)
    }
  }, [])

  useEffect(() => {
    window.onbeforeunload = function () {
      let expiry = new Date().getTime() + 5 * 60000 // 5 minutes
      localStorage.setItem('consentExpiry', expiry.toString())
    }
    window.addEventListener('click', () => {
      let expiry = new Date().getTime() + 5 * 60000 // 5 minutes
      localStorage.setItem('consentExpiry', expiry.toString())
    })
  }, [])

  const getExpiry = useCallback(() => {
    if (localStorage.getItem('consentExpiry')) {
      return localStorage.getItem('consentExpiry')
    } else {
      return '0'
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('consent') === 'true') {
      setConsent(true)
      setWebConsent(true)
      toggleModal(false)
    }
  }, [showModal, webConsent])

  const toggleHandler = (): void => {
    togglePrivacyPolicy((prev) => !prev)
  }

  return (
    <div className={`main-layout ${props?.layoutStyle || ''}`}>
      <Header {...{ ...props }} />
      <div className={`main-content ${props?.contentStyle || ''}`}>{children}</div>
      <Footer {...{ ...props }} />

      <DisplayModal
        privacyPolicy={privacyPolicy}
        showModal={showModal}
        togglePrivacyPolicy={togglePrivacyPolicy}
        toggleModal={toggleModal}
        consent={consent}
        toggleHandler={toggleHandler}
        webConsent={webConsent}
        setWebConsent={setWebConsent}
      />
    </div>
  )
}

export default Layout
